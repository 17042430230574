import {createClient, configureChains} from 'wagmi';
import {
  mainnet,
  polygon,
  arbitrum,
  optimism,
  avalanche,
  bsc,
} from 'wagmi/chains';
import {jsonRpcProvider} from 'wagmi/providers/jsonRpc';
import {WalletConnectConnector} from 'wagmi/connectors/walletConnect';
import {MetaMaskConnector} from 'wagmi/connectors/metaMask';
import {CoinbaseWalletConnector} from 'wagmi/connectors/coinbaseWallet';
import {InjectedConnector} from 'wagmi/connectors/injected';
import {SafeConnector} from '@gnosis.pm/safe-apps-wagmi';
import {getRpcUrl} from './ankrRpc';

const {chains, provider} = configureChains(
  [
    mainnet,
    polygon,
    arbitrum,
    avalanche,
    bsc,
    optimism,
    {
      id: 80002,
      name: 'Polygon Amoy',
      network: 'polygon-amoy',
      nativeCurrency: {
        symbol: 'POL',
        decimals: 18,
        name: 'POL',
      },
      rpcUrls: {
        default: {
          http: ['https://rpc.ankr.com/polygon_amoy'],
        },
        public: {
          http: ['https://rpc.ankr.com/polygon_amoy'],
        },
      },
      blockExplorers: {
        default: {
          name: 'Polygon amoy',
          url: 'https://amoy.polygonscan.com',
        },
      },
    },
    {
      id: 8453,
      name: 'Base',
      network: 'base',
      nativeCurrency: {
        symbol: 'ETH',
        decimals: 18,
        name: 'Ether',
      },
      rpcUrls: {
        default: {
          http: ['https://rpc.ankr.com/base'],
        },
        public: {
          http: ['https://rpc.ankr.com/base'],
        },
      },
      blockExplorers: {
        default: {
          name: 'Base Explorer',
          url: 'https://basescan.org',
        },
      },
    },
    {
      id: 84532,
      name: 'Base Sepolia',
      network: 'base-sepolia',
      nativeCurrency: {
        symbol: 'ETH',
        decimals: 18,
        name: 'Sepolia Ether',
      },
      rpcUrls: {
        default: {
          http: ['https://rpc.ankr.com/base_sepolia'],
        },
        public: {
          http: ['https://rpc.ankr.com/base_sepolia'],
        },
      },
      blockExplorers: {
        default: {
          name: 'Base Sepolia Explorer',
          url: 'https://sepolia.basescan.org',
        },
      },
    },
    {
      id: 11155111,
      name: 'Sepolia',
      network: 'sepolia',
      nativeCurrency: {
        symbol: 'ETH',
        decimals: 18,
        name: 'Sepolia Ether',
      },
      rpcUrls: {
        default: {
          http: ['https://rpc.ankr.com/eth_sepolia'],
        },
        public: {
          http: ['https://rpc.ankr.com/eth_sepolia'],
        },
      },
      blockExplorers: {
        default: {
          name: 'Sepolia Explorer',
          url: 'https://sepolia.etherscan.io',
        },
      },
    },
  ],
  [
    jsonRpcProvider({
      rpc: pChain => {
        const url = getRpcUrl(pChain.id);
        return url ? {http: url} : {http: pChain.rpcUrls.default.http[0]};
      },
      priority: 0,
      stallTimeout: 1000,
    }),
  ]
);

const client = createClient({
  connectors: [
    new MetaMaskConnector({chains}),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: 'd4ff784f1ca127ac751a664c22836ac5',
        showQrModal: true,
        metadata: {
          name: 'Paywong',
          description: 'Crypto payment gateway',
          url: 'https://paywong.com',
          icons: ['https://app.paywong.com/images/logo.png'],
        },
      },
    }),
    new CoinbaseWalletConnector({
      options: {
        appName: 'Paywong',
      },
      chains,
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
    // @ts-ignore
    new SafeConnector({chains}),
  ],
  provider,
  autoConnect: true,
});

export {chains, client};
